<template>
  <div>
    <div class="active">
      <p class="title">{{ appName }} Fulfillment</p>
      <p class="tip">Email verification successful</p>
      <img src="../assets/img/success.png">
      <p class="time">
        Automatically jump to Dashboard after
        <span>{{ time }}</span> seconds
      </p>
    </div>
  </div>
</template>
<script>
import { emailConfirm } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName'
    })
  },
  data() {
    return {
      time: 6
    }
  },
  async mounted() {
    const token = this.$route.query.token
    console.log(token)
    const { code, msg } = await emailConfirm({ ctoken: token })
    if (code === 200) {
      const _this = this
      const set = setInterval(() => {
        _this.time -= 1
        if (_this.time === 0) {
          _this.$router.replace('/')
          clearInterval(set)
        }
      }, 1000)
    } else {
      this.$message.error(msg)
    }
  }
}
</script>
<style scoped lang="less">
.active {
  width: 1000px;
  margin: 0 auto;
  margin-top: 80px;
  text-align: center;
  .title {
    font-size: 18px;
    color: #e72e48;
  }
  .tip {
    font-size: 25px;
  }
  img {
    width: 90px;
    height: 90px;
  }
  .time {
    margin-top: 20px;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
